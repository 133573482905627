<template>
    <div v-if="show"
         class="fixed w-fit right-0 animation-scroll z-[800]"
         ref="popup"
         :class="posY"
    >
        <div @click.self="callToAction"
             class="relative w-[19.5rem] m-4 pt-5 pb-8 z-40 flex flex-col justify-center items-center rounded-xl bg-forest shadow-2xl group cursor-pointer"
        >
            <icons-x-solid
                @click.prevent.stop="closePopUp"
                class="absolute cursor-pointer right-0 top-0 mr-1 mt-0.5 w-7 text-white hover:bg-grey5/20 rounded-full p-1">
            </icons-x-solid>

            <nuxt-img
                @click="callToAction"
                src="/images/pop-up/bonnes-affaires.png"
                alt="Les bonnes affaires sont de sortie"
                densities="1x"
                class="h-full">
            </nuxt-img>

            <c-button
                @click="callToAction"
                class="bg-forest3 text-white px-8 !py-3 !font-semibold !text-lg"
            >
                Découvrir
            </c-button>
        </div>
    </div>
</template>

<script setup>
import {useUserStore} from "~/store/user";
import {watchOnce} from "@vueuse/core";
import useGtmTrackEvent from "~/composables/gtmTracking";

const router = useRouter();
const route = useRoute();
const storageShow = ref(false);

const posY = ref('mt-28');
const popup = ref();

const disabledOnPages = computed(() => {
    return [
        'connexion'
    ];
});

const show = computed(() => {
    return (
        storageShow.value === true &&
        useUserStore().isLoggedIn === false &&
        useNuxtApp().$isMobile.value === false &&
        useNuxtApp().$isTablette.value === false &&
        disabledOnPages.value.includes(route.name) === false
    );
});

onMounted(() => {
    storageShow.value = JSON.parse(localStorage.getItem('popUpShown') || 'true');
});

onBeforeUnmount(() => {
    window.removeEventListener('scroll', listener);
});

function callToAction() {
    closePopUp();
    router.push({name: 'catalogue', query: {promos: 'true'}});
}

function closePopUp() {
    storageShow.value = false;
    localStorage.setItem('popUpShown', JSON.stringify(false));

    useGtmTrackEvent({
        event: 'click_popup',
        target: 'promos',
        creative: '06-2024'
    });
}

function addScrollListener() {
    window.addEventListener('scroll', listener);
}

function listener() {
    posY.value = window.scrollY > 0 ? 'mt-0' : 'mt-28';
}

watchOnce(() => popup.value, () => {
    if (show.value === true && getComputedStyle(popup.value).getPropertyValue('--active-listener') === '1') {
        addScrollListener();
    }
});
</script>

<style scoped lang="scss">
@supports (animation-timeline: scroll(y)) {
    .animation-scroll {
        animation: 200ms ease-in-out 0s smooth-margin;
        animation-timeline: scroll(y);
        transition: margin-top 200ms ease-in-out 0s;
    }

    @keyframes smooth-margin {
        0% {
            margin-top: 7rem;
        }

        10%, 100% {
            margin-top: 0;
        }
    }
}

@supports not (animation-timeline: scroll(y)) {
    .animation-scroll {
        --active-listener: 1;
    }
}
</style>
